export const female_nocarbs = {
  Name: 'female_nocarbs',
  Landing: {
    heading: 'Discover how you can lose weight',
    description: 'by solving inflammation issues',
  },
  Quiz: {
    defaultProperties: [
      {
        question: 'gender',
        answer: 'female',
      },
    ],
    quizFlow: [
      // {
      //   type: 'landing',
      // },
      {
        question: 'Choose your goal',
        subtitle: 'Please select your main issue',
        questionCode: 'goal',
        answers: [
          {
            text: 'Weight Loss',
            image: '/static/images/wl.png',
          },
          {
            text: 'Fit Body',
            image: '/static/images/fb.png',
          },
          {
            text: 'Tone Muscles',
            image: '/static/images/tm.png',
          },
        ],
        type: 'question',
      },
      {
        question: 'Choose your body type',
        questionCode: 'body_type',
        answers: [
          {
            text: 'Regular',
            image: '/static/images/regular.png',
          },
          {
            text: 'Plump',
            image: '/static/images/plump.png',
          },
          {
            text: 'Extra Plump',
            image: '/static/images/extra-plump.png',
          },
        ],
        type: 'question',
      },
      {
        question: 'Choose the body you want',
        questionCode: 'body_want',
        answers: [
          {
            text: 'Fit',
            image: '/static/images/fit.png',
          },
          {
            text: 'Muscular',
            image: '/static/images/muscular.png',
          },
          {
            text: 'Shapely',
            image: '/static/images/shapely.png',
          },
        ],
        type: 'question',
      },
      {
        question: 'Choose your target zones',
        questionCode: 'target_zones',
        answers: [
          {
            text: 'Legs',
            image: '/static/images/slimLegs.png',
          },
          {
            text: 'Butt',
            image: '/static/images/tonedButt.png',
          },
          {
            text: 'Chest',
            image: '/static/images/perkyBreasts.png',
          },
          {
            text: 'Belly',
            image: '/static/images/flatBelly.png',
          },
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'How do you typically spend your day?',
        subtitle: 'Choose what takes up most of your time.',
        questionCode: 'typical_day',
        answers: [
          'Sitting all day long at work',
          "I'm always on my feet",
          'Doing lots of physical activity',
          'Staying at home',
        ],
        type: 'question',
      },
      {
        question: 'When was the last time you were content with your body weight?',
        questionCode: 'content_weight',
        answers: [
          {
            text: 'Less than a year ago',
            explainer: {
              title: '🙌 Thanks for sharing!',
              description:
                'We learned that a lot of people have faced the same as well. Inflammi has a clear weight loss plan that is easy to follow. Also, the program will help you with motivation during this journey.',
            },
          },
          {
            text: '1 - 3 years ago',
            explainer: {
              title: '🙌 Thanks for sharing!',
              description:
                'We learned that a lot of people have faced the same as well. Inflammi has a clear weight loss plan that is easy to follow. Also, the program will help you with motivation during this journey.',
            },
          },
          {
            text: 'More than 3 years ago',
            explainer: {
              title: '🙌 Thanks for sharing!',
              description:
                'We learned that a lot of people have faced the same as well. Inflammi has a clear weight loss plan that is easy to follow. Also, the program will help you with motivation during this journey.',
            },
          },
          {
            text: 'Never',
            explainer: {
              title: '🙌 Thanks for sharing!',
              description:
                'We learned that a lot of people have faced the same as well. Inflammi has a clear weight loss plan that is easy to follow. Also, the program will help you with motivation during this journey.',
            },
          },
        ],
        type: 'question',
        isExplainerSelect: true,
      },
      {
        question: 'Do you workout?',
        title:
          'Workouts boost your weight loss results. We can help you make it a daily habit.',
        questionCode: 'physical_activity_level',
        answers: [
          {
            text: 'No, I don’t',
            explainer: {
              title: '💪 37% of users*',
              description:
                'responded in the same way. Inflammi will help you create a habit of working out.',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
          {
            text: 'Only walks',
            explainer: {
              title: '💪 37% of users*',
              description:
                'responded in the same way. Inflammi will help you create a habit of working out.',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
          {
            text: '1-2 times a week',
            explainer: {
              title: '💪 You’ve worked out more than 62% of users*',
              description: 'It will be easier for you to maintain a workout plan.',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
          {
            text: '3-5 times a week',
            explainer: {
              title: '💪 You’ve worked out more than 62% of users*',
              description: 'It will be easier for you to maintain a workout plan.',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
        ],
        type: 'question',
        isExplainerSelect: true,
      },
      {
        question: 'How tired do you typically feel during the day?',
        subtitle:
          'Our weight loss programs help you keep your energy level steady throughout the day.',
        questionCode: 'how_tired',

        answers: [
          {
            text: 'I feel tired all day long',
            image: '/static/images/tired.svg',
          },
          {
            text: 'I feel tired before meals',
            image: '/static/images/tired2.svg',
          },
          {
            text: 'I have a couple of afternoon yawns',
            image: '/static/images/yawns.svg',
          },
          {
            text: "I'm a ball of fire all day long",
            image: '/static/images/fire.svg',
          },
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/info2.png',
        maxWidth: '80%',
        description:
          '<div style="text-align: left;"><i>"I\'m amazed by how the Inflammi app resonates with me and effortlessly integrates into my busy lifestyle. It feels like the app knows precisely what I require to manage my weight. The boost in my daily efficiency is remarkable! I\'ve established a routine that reduces stress and brings balance back into my life. For anyone aiming to achieve tangible health improvements, especially in weight management, this app is essential!"</i><br /><br />Alexa J., 28</div>',
        button: 'Continue',
      },
      {
        type: 'input',
        heading: 'What is your height?',
        questionCode: 'height_input',
        button: 'Continue',
        param: 'height',
      },
      {
        type: 'input',
        heading: 'What is your current weight?',
        questionCode: 'current_weight_input',
        button: 'Continue',
        param: 'weight',
      },
      {
        type: 'input',
        heading: 'What is your desired weight?',
        questionCode: 'desired_weight_input',
        button: 'Continue',
        param: 'desired_weight',
      },
      {
        type: 'input',
        heading: 'What is your age?',
        questionCode: 'age_input',
        button: 'Continue',
        param: 'age',
      },
      {
        question: 'Select all that you tend to do:',
        questionCode: 'tend_to',
        image: '/static/images/q2.png',
        answers: [
          'I eat late at night',
          "I can't give up eating sweets",
          'I love soft drinks',
          'I consume hard drinks from time to time',
          'I love fatty or salty foods',
          'None of the above',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'How much sleep do you typically get?',
        questionCode: 'sleep_duration',
        image: '/static/images/q4.png',
        answers: ['Less than 5 hours', '5-6 hours', '7-8 hours', 'More than 8 hours'],
        type: 'question',
      },
      {
        question: 'How much water do you usually drink?',
        subtitle: 'We mean clean water, excluding coffee, tea, and other drinks',
        questionCode: 'daily_water_intake',
        answers: [
          {
            text: 'Only coffee or tea',
            image: '/static/images/coffee.svg',
            explainer: {
              title: '💧 Drinking water is essential',
              description:
                "If you're not hydrated, your body can't perform at its highest level. Inflammi will remind you to drink enough water.",
            },
          },
          {
            text: 'Less than 2 glasses (16 Oz)',
            image: '/static/images/water1.svg',
            explainer: {
              title: '💧 Drinking water is essential',
              description:
                "If you're not hydrated, your body can't perform at its highest level. Inflammi will remind you to drink enough water.",
            },
          },
          {
            text: '2-6 glasses (16-48 Oz)',
            image: '/static/images/water2.svg',
            explainer: {
              title: '💧 Wow! Impressive!',
              description: 'You drink more water than 75% of users*. Keep it up!',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
          {
            text: '7-10 glasses (56-80 Oz)',
            image: '/static/images/water3.svg',
            explainer: {
              title: '💧 Wow! Impressive!',
              description: 'You drink more water than 75% of users*. Keep it up!',
              disclaimer: '*users of Inflammi who took the quiz.',
            },
          },
          {
            text: "I don't count, it depends",
            image: '/static/images/dunno.svg',
          },
        ],
        type: 'question',
        isExplainerSelect: true,
      },
      {
        question: 'Do you follow any of these diets?',
        questionCode: 'diets',
        answers: [
          {
            text: "I'm lactose intolerant",
            image: '/static/images/no_milk.svg',
          },
          {
            text: "I don't eat gluten",
            image: '/static/images/no_gluten.svg',
          },
          {
            text: "I'm vegetarian",
            image: '/static/images/vegeterian.svg',
          },
          {
            text: "I'm vegan",
            image: '/static/images/vegan.svg',
          },
          {
            text: 'None of the above',
          },
        ],
        type: 'question',
        isMultiSelect: true,
      },
      {
        question: 'Do you have any food allergies?',
        subtitle: 'Your allergies will be considered in your nutrition plan.',
        questionCode: 'food_allergies',
        image: '/static/images/q3.png',
        answers: [
          'Lactose',
          'Eggs',
          'Peanuts',
          'Gluten',
          'Soy',
          'Fish',
          'Shellfish',
          'Citrus fruits',
          'None',
        ],
        type: 'question',
        isMultiSelect: true,
      },
      // {
      //   question: "Which carbs or starchy foods don't you eat?",
      //   subtitle: "We'll keep this in mind for your diet plan.",
      //   questionCode: 'avoided_carbs',
      //   image: '/static/images/q7.png',
      //   answers: ['I eat all kinds', 'Pasta', 'White potatoes', 'Couscous', 'Sorghum'],
      //   type: 'question',
      //   isMultiSelect: true,
      // },
      // {
      //   question: 'Is there a special occasion you want to lose weight for?',
      //   subtitle:
      //     'You’re more likely to reach your goal if you have something important to aim for',
      //   questionCode: 'occasion',
      //   answers: [
      //     {
      //       text: 'Vacation',
      //       image: '/static/images/icon_vacation.svg',
      //     },
      //     {
      //       text: 'Wedding',
      //       image: '/static/images/icon_wedding.svg',
      //     },
      //     {
      //       text: 'Sporting event',
      //       image: '/static/images/icon_sporting_event.svg',
      //     },
      //     {
      //       text: 'Beach trip',
      //       image: '/static/images/icon_beach_trip.svg',
      //     },
      //     {
      //       text: 'Reunion',
      //       image: '/static/images/icon_reunion.svg',
      //     },
      //     {
      //       text: 'Birthday',
      //       image: '/static/images/icon_family_occasion.svg',
      //     },
      //     {
      //       text: 'Other',
      //       image: '/static/images/icon_other.svg',
      //     },
      //     {
      //       text: 'No',
      //       image: '/static/images/icon_none.svg',
      //     },
      //   ],
      //   type: 'question',
      // },
      // {
      //   type: 'input',
      //   heading: 'When is your event?',
      //   subtitle:
      //     'Once we know this, we’ll be able to put together a personalized plan to help you get in shape. Your data will not be shared with any third parties.',
      //   questionCode: 'event_date',
      //   button: 'Continue',
      //   param: 'event',
      // },
    ],
  },
  GetStarted: {
    description:
      'Our team has crafted a plan specifically tailored to your parenting needs. Ensure a brighter future for you and your child today.',
    button: 'Continue',
  },
  Summary: {
    heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Parenting empowerment',
    issue: 'parental excellence',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: 'Build parental confidence',
        description: 'Equip yourself with the tools to face parenting challenges head-on',
      },
      {
        heading: 'Enhance your efficiency',
        description:
          'Master the art of multitasking and time management to be there for your child',
      },
      {
        heading: 'Solidify parental resolve',
        description:
          'Effective strategies for consistent parenting, even when the going gets tough',
      },
      {
        heading: 'Alleviate parental stress',
        description:
          'Methods for managing stress, ensuring a calmer home environment for your child',
      },
      {
        heading: "Become your child's hero",
        description:
          'Long-term strategies to build a stable, loving home for your child to thrive in',
      },
      {
        heading: 'Elevate family dynamics',
        description:
          'Strengthen communication and empathy within the family, creating a harmony',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};
